import { FC } from "react";
import { config } from "@constants";
import { useGetHomaPage } from "@metaverse/lib/queries";
import DynamicHomePage from "./Home.page";

const HomePage: FC = () => {
  const { data } = useGetHomaPage(config?.CONTENTFUL_SM_HOME_ENTRY_ID);
  const sections = data?.fields?.sections;

  if (!sections) return null;

  return <DynamicHomePage sections={sections} />;
};

export default HomePage;
